﻿const wrapper = document.getElementById('app-wrapper');

/**
 * Initialise function for handling the site webp images 
 */
export async function initWebpListeners() {
    toggleWebpSupport();
}

/**
 * Determine if the browser supports
 * webp images or not. Generates a raw
 * webp image of 1x1px as a test.
 * @returns {Promise<Boolean>}
 */
function supportsWebp() {
    return new Promise(resolve => {
        const image = new Image();
        image.onerror = () => resolve(false);
        image.onload = () => resolve(image.width === 1);
        image.src = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=';
    }).catch(() => Promise.resolve(false));
}

/**
 * Add the appropriate webp class to the document root
 */
async function toggleWebpSupport() {
    if (await supportsWebp()) {
        wrapper?.classList.remove('no-webp');
        wrapper?.classList.add('webp');
    }
}