﻿/**
 * Add the necessary script for the "skip to..." links menu 
 */
export default function () {
    const content = document.querySelector('#content');
    const target = document.getElementById('skip-to-content');

    if (!content || !target) {
        return;
    }

    content.tabIndex = -1;

    target.addEventListener('click', function (e) {
        e.preventDefault();
        content.focus();
    });
}